import axios from "axios";
import Disqus from "disqus-react";
import Markdown from "markdown-to-jsx";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";

function BlogDetails(props) {
  const params = useParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [description, setDescription] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [featuredImage, setFeaturedImage] = useState("");
  const blogId = params.id;
  const blogFile = params.title;
  const fileName = `${blogFile}.md`;
  // const originalTitle = params.originalTitle
  useEffect(() => {
    import(`../blog/${fileName}`)
    .then((res) => {
      fetch(res.default)
      .then((res) => res.text())
      .then((res) => {
        setContent(res);
           
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  });
  useEffect(() => {
    let mounted = true;
    axios.get("/api/blog-data",  { params: { "blogId": parseInt(blogId) } }).then((res) => {
      // if (mounted) {
      //   setPosts(response.data);
      // }
      // console.log(res);
      setTitle(res.data.title)
      setDescription(res.data.description)
      setPublishDate(`${res.data.createDay} ${res.data.createMonth}, ${res.data.createYear}`)
      setFeaturedImage(res.data.featuredImage)
      // description = res.slice(0, 160);

    });
    return () => (mounted = false);
  }, []);
  // const disqusShortname = "chester-react"; //found in your Disqus.com dashboard
  // const disqusConfig = {
  //   url: "https://tf-react-chester.now.sh/", //Homepage link of this site.
  //   identifier: blogId,
  //   title: blogFile,
  // };

  return (
    <Layout>
      <Helmet>
        {/* <title>{blogFile}</title>
        <meta
          name="description"
          content="Ali KHYAR Portfolio Blog Details Page"
        /> */}
        {/* SEO */}
        <title>{title}</title>
        {/* <title>{blogFile.split("part")[0].split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}</title> */}

        <meta
          name="description"
          content={description}
        />
        <meta
          name="keywords"
          content="IT, Cloud, DevOps, Ali KHYAR, pipelines"
        />
        <meta name="author" content="Ali KHYAR" />
        <meta name="date" content={publishDate} />
        <meta name="category" content="DevOps" />
        {/* <meta name="subcategory" content="Workflow Management" /> */}
        <meta
          name="tags"
          content="IT, Cloud, DevOps, Ali KHYAR, pipelines"
        />
        <meta property="og:image" content={featuredImage} />
      </Helmet>
      <div className="mi-blog-details mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          {/* <h1>{params.originalTitle}</h1> */}
          <Markdown>{content}</Markdown>
          {/* <div className="mi-blog-details-comments mt-30">
            <Disqus.DiscussionEmbed
              shortname={disqusShortname}
              config={disqusConfig}
            />
          </div> */}
        </div>
      </div>
    </Layout>
  );
}

export default BlogDetails;
