import Mock from "../mock";

const database = {
  information: {
    name: 'Ali KHYAR',
    aboutContent: "Welcome to my portfolio 😃 I'm currently a Cloud DevOps Engineer, but I had experience with other IT fields such as Web-Dev. I always try to improve my skills by learning from people and sharing with them. Keep an eye on the blog, I share stuff I learn/know there.",
    age: 24,
    phone: '',
    nationality: 'Moroccan',
    language: 'English, French, Arabic, Tamazight',
    email: '',
    address: 'Rabat, Rabat-Salé-Kenitra, Morocco',
    freelanceStatus: 'Yes',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/alikhyar',
      dribbble: '',
      github: 'https://github.com/AliKHYAR'
    },
    brandImage: 'https://avatars.githubusercontent.com/u/44535965?s=400&u=575c90caf3ed44bacce1e6e79b993b089b20b951&v=4',
    aboutImage: './images/infra.jpg',
    aboutImageLg: './images/infra.jpg',
    cvfile: './files/en_cv_AliKHYAR.pdf'
  },
  services: [
    {
      title: "DevOps & Cloud Development",
      icon: 'cloud',
      details: ""
    },
    {
      title: "Web Development",
      icon: 'website-alt',
      details: ""
    },
    {
      title: "Automation",
      icon: 'target-revenue',
      details: ""
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Be a client, and let's put your name here.",
      author: {
        name: 'Your Name',
        designation: 'You Designation'
      }
    }
  ],
  skills: [
    {
      title: "Python",
      value: 90
    },
    {
      title: "Azure",
      value: 70
    },
    {
      title: "Docker",
      value: 80
    },
    {
      title: "Kubernetes",
      value: 70
    },
    {
      title: "Linux",
      value: 75
    },
    {
      title: "Front-End Stack",
      value: 65
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "./images/portfolio-image-1.jpg",
      largeImageUrl: ["./images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 2,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "./images/portfolio-image-4.jpg",
      largeImageUrl: [
        "./images/portfolio-image-4-lg.jpg",
        "./images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 3,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "./images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 4,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "./images/portfolio-image-3.jpg",
      largeImageUrl: ["./images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 5,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "./images/portfolio-image-5.jpg",
      largeImageUrl: ["./images/portfolio-image-5-lg.jpg"]
    },
    {
      id: 6,
      title: "Pendrive",
      subtitle: "Free pendrive mockup design.",
      imageUrl: "./images/portfolio-image-6.jpg",
      largeImageUrl: ["./images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 7,
      title: "Beautiful Pendrive",
      subtitle: "Pendrive with great design & flexible.",
      imageUrl: "./images/portfolio-image-7.jpg",
      largeImageUrl: ["./images/portfolio-image-7-lg.jpg"],
      url: 'https://twitter.com'
    },
    {
      id: 8,
      title: "Sticker",
      subtitle: "Clip sticker mockup design.",
      imageUrl: "./images/portfolio-image-8.jpg",
      largeImageUrl: ["./images/portfolio-image-8-lg.jpg"]
    },
    {
      id: 9,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "./images/portfolio-image-9.jpg",
      largeImageUrl: ["./images/portfolio-image-9-lg.jpg"]
    },
    {
      id: 10,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "./images/portfolio-image-3.jpg",
      largeImageUrl: ["./images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 11,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "./images/portfolio-image-4.jpg",
      largeImageUrl: [
        "./images/portfolio-image-4-lg.jpg",
        "./images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 12,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "./images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 13,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "./images/portfolio-image-1.jpg",
      largeImageUrl: ["./images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 14,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "./images/portfolio-image-5.jpg",
      largeImageUrl: ["./images/portfolio-image-5-lg.jpg"]
    },
    {
      id: 15,
      title: "Pendrive",
      subtitle: "Free pendrive mockup design.",
      imageUrl: "./images/portfolio-image-6.jpg",
      largeImageUrl: ["./images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 16,
      title: "Beautiful Pendrive",
      subtitle: "Pendrive with great design & flexible.",
      imageUrl: "./images/portfolio-image-7.jpg",
      largeImageUrl: ["./images/portfolio-image-7-lg.jpg"],
      url: 'https://twitter.com'
    },
    {
      id: 17,
      title: "Sticker",
      subtitle: "Clip sticker mockup design.",
      imageUrl: "./images/portfolio-image-8.jpg",
      largeImageUrl: ["./images/portfolio-image-8-lg.jpg"]
    },
    {
      id: 18,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "./images/portfolio-image-9.jpg",
      largeImageUrl: ["./images/portfolio-image-9-lg.jpg"]
    },
    {
      id: 19,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "./images/portfolio-image-1.jpg",
      largeImageUrl: ["./images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 20,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "./images/portfolio-image-4.jpg",
      largeImageUrl: [
        "./images/portfolio-image-4-lg.jpg",
        "./images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 21,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "./images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 22,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "./images/portfolio-image-3.jpg",
      largeImageUrl: ["./images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 23,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "./images/portfolio-image-5.jpg",
      largeImageUrl: ["./images/portfolio-image-5-lg.jpg"]
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2022 - 2022",
        position: "Cloud DevOps Engineer",
        company: "SQLI",
        details: "Cloud deployment and management."
      },
      {
        id: 2,
        year: "2020 - 2022",
        position: "Service Engineer",
        company: "Acelium",
        details: "Confidential"
      },
      {
        id: 3,
        year: "2020 - 2022",
        position: "Infrastructure Engineer",
        company: "Acentium",
        details: "Confidential"
      },
      {
        id: 4,
        year: "2020 - 2020",
        position: "Junior DevOps Engineer",
        company: "VEXXHOST Inc.",
        details: "Manage public and private cloud deployments with Python, OpenStack, Git, Kubernetes and Helm."
      },
      {
        id: 5,
        year: "2020 - 2020",
        position: "Web Developer Intern",
        company: "Techno Spider",
        details: "Worked on an e-commerce application with Django and the basic front-end stack (html/css/js), Integrated all the needed functionalities, ex: managing store products, payment and delivery ensuring"
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2018 - 2019",
        graduation: "Master of Science",
        university: "Abc University",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 2,
        year: "2016 - 2018",
        graduation: "Bachelor of Science",
        university: "Abc University",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 3,
        year: "2015 - 2016",
        graduation: "Higher Schoold Graduation",
        university: "Abc College",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      }
    ]
  },
  blogs: [
    {
      id: 5,
      title: 'Airflow: Deep Dive | All you need to know about Airflow',
      featuredImage: './images/airflow-data-pipeline.png',
      meta: '',
      filesource: '../../blog/airflow-deepdive.md',
      createDay: "24",
      createMonth: 'Feb',
      createYear: "2023",
      description: 'This blog post provides a comprehensive overview of Airflow, a popular data pipeline orchestration tool. The post covers Airflow architecture, components, and best practices.'
    },
    {
      id: 4,
      title: 'Ansible 101 - a newbie guide to ansible - Part 1/2',
      featuredImage: './images/ansible-guide.png',
      meta: '',
      filesource: '../../blog/ansible-101-part-1-2.md',
      createDay: "03",
      createMonth: 'Dec',
      createYear: "2022",
      description: 'This is part 1 of a beginner-friendly guide to Ansible, a popular configuration management tool. The post covers Ansible architecture, installation, and basic usage.'
    },
    {
      id: 3,
      title: 'Terraform 101 - Part 3/3: Modules, Built-in Functions, Type Constraints, and Dynamic Blocks.',
      featuredImage: './images/terraform-basics.jpg',
      meta: '',
      filesource: '../../blog/terraform-101-part-3-3-modules-built-in-functions-type-constraints-and-dynamic-blocks.md',
      createDay: "17",
      createMonth: 'April',
      createYear: "2022",
      description: 'This is part 3 of a series of blog posts about Terraform, a popular infrastructure-as-code tool. The post covers advanced topics such as modules, built-in functions, and dynamic blocks.'
    },
    {
      id: 2,
      title: 'Terraform 101 - Part 2/3: State, Variables, Outputs, and provisioners.',
      featuredImage: './images/terraform-basics.jpg',
      meta: '',
      filesource: '../../blog/terraform-101-part-2-3-state-variables-outputs-and-provisioners.md',
      createDay: "20",
      createMonth: 'April',
      createYear: "2020",
      description: 'This is part 2 of a series of blog posts about Terraform, a popular infrastructure-as-code tool. The post covers intermediate topics such as state management, variables, and provisioners.'
    },
    {
      id: 1,
      title: 'Terraform 101 - Part 1/3: History, Workflow, and Resource Addressing.',
      featuredImage: './images/terraform-basics.jpg',
      meta: '',
      filesource: '../../blog/terraform-101-part-1-3-history-workflow-and-resource-addressing.md',
      createDay: "04",
      createMonth: 'June',
      createYear: "2022",
      description: 'This is part 1 of a series of blog posts about Terraform, a popular infrastructure-as-code tool. The post covers Terraform history, workflow, and resource addressing.'
    }
  ],
  contactInfo: {
    phoneNumbers: [''],
    linkedinAddress: "/in/AliKhyar",
    emailAddress: [''],
    address: "Rabat, Rabat-Salé-Kenitra, Morocco "
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet(`/api/blog-data`).reply(config => {
  const blogId = parseInt(config.params.blogId);
  if (Number.isNaN(blogId)) {
    return [400, { message: "Invalid blog ID" }];
  }
  const blog = database.blogs.find(blog => blog.id === blogId);
  if (blog) {
    return [200, blog];
  } else {
    return [404, { message: "Blog not found" }];
  }
});



// Replace `blogId` with the ID of the blog post you want to retrieve
// const blogId = 1;

// axios
//   .get(`/api/blog/${blogId}`)
//   .then((response) => {
//     console.log(response.data);
//   })
//   .catch((error) => {
//     console.error(error);
//   });

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});
